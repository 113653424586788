var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0 d-flex"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Legal person")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchFilter,
      expression: "searchFilter"
    }],
    staticClass: "form-control form-control-solid mr-4 min-w-300px",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('Search')
    },
    domProps: {
      "value": _vm.searchFilter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchFilter = $event.target.value;
      }
    }
  }), _c('euro-select', {
    staticClass: "min-w-200px mr-4 mb-0",
    attrs: {
      "multiselect-props": _vm.filterRoleMultiselectProps,
      "placeholder": _vm.$t('Filter Role')
    },
    model: {
      value: _vm.getRole,
      callback: function callback($$v) {
        _vm.getRole = $$v;
      },
      expression: "getRole"
    }
  }), _vm.user.is_ap_staff ? _c('euro-select', {
    staticClass: "min-w-200px mr-4 mb-0",
    attrs: {
      "multiselect-props": _vm.filterAdministrativeMultiselectProps
    },
    model: {
      value: _vm.getAdministrative,
      callback: function callback($$v) {
        _vm.getAdministrative = $$v;
      },
      expression: "getAdministrative"
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'create-legal-person'
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Home/Building.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Create")) + " ")])])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "fields": _vm.fields,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "filter": _vm.searchFilter
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.name",
      fn: function fn(_ref) {
        var _item$name;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : item.logo,
            "avatar-text": item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name[0]
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bold",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(_vm.getActiveVat(item.extracompanyvat_set)))]), _c('div', {
          staticClass: "text-muted font-size-xs"
        }, [_vm._v(_vm._s(_vm.getActiveFiscalCode(item.companyfiscalid_set)))])])], 1)];
      }
    }, {
      key: "cell.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-normal h-100"
        }, [_vm._v(_vm._s(_vm.formattedDate(item.created_at)))])];
      }
    }, {
      key: "cell.telephone",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.telcontactscompany_set.length ? _c('div', [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.getMainTel(item.telcontactscompany_set)))]), _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.getMainTelKind(item.telcontactscompany_set)))])]) : _vm._e()];
      }
    }, {
      key: "cell.email",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.emailcompany_set.length ? _c('div', [_c('div', {
          staticClass: "font-weight-normal"
        }, [_vm._v(_vm._s(_vm.getMainEmail(item.emailcompany_set)))]), _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(_vm._s(_vm.getMainEmailKind(item.emailcompany_set)))])]) : _vm._e()];
      }
    }, {
      key: "cell.secondment_participation",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex flex-wrap gap-1"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": item.secondment_client_relations.length > 0 ? _vm.$t('Action disabled, used on Assignment') : ''
          }
        }, [_c('client-button', {
          attrs: {
            "active": item.is_client_on_secondments,
            "disabled": item.secondment_client_relations.length > 0
          },
          on: {
            "click": function click($event) {
              return _vm.setAsClientFunction(item, !item.is_client_on_secondments);
            }
          }
        })], 1), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": item.secondment_contractor_relations.length > 0 ? _vm.$t('Action disabled, used on Assignment') : ''
          }
        }, [_c('contractor-button', {
          attrs: {
            "active": item.is_contractor_on_secondments,
            "disabled": item.secondment_contractor_relations.length > 0
          },
          on: {
            "click": function click($event) {
              return _vm.setAsContractorFunction(item, !item.is_contractor_on_secondments);
            }
          }
        })], 1), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "title": item.secondment_partner_relations.length > 0 ? _vm.$t('Action disabled, used on Assignment') : ''
          }
        }, [_c('supplier-button', {
          attrs: {
            "active": item.is_partner_on_secondments,
            "disabled": item.secondment_partner_relations.length > 0
          },
          on: {
            "click": function click($event) {
              return _vm.setAsPartnerFunction(item, !item.is_partner_on_secondments);
            }
          }
        })], 1)])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [!item.is_administrative_structure ? _c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-sm",
          class: {
            'btn-hover-primary': !_vm.isDeleteButtonDisabled(item)
          },
          attrs: {
            "title": _vm.isDeleteButtonDisabled(item) ? _vm.$t('Delete not allowed, used on Assignment') : _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              !_vm.isDeleteButtonDisabled(item) ? _vm.remove(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': !_vm.isDeleteButtonDisabled(item)
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }