<template>
  <b-overlay :show="$attrs.loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
    <button class="btn font-weight-bolder btn-xs" :class="{ 'btn-light-primary': !active, 'btn-light-success': active }"
      :disabled="disabled" v-bind="{ ...$attrs }" v-on="$listeners">
      {{ $t("Supplier") }}
    </button>
  </b-overlay>
</template>

<script>
export default {
  inheritAttrs: true,

  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
};
</script>
