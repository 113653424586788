import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

/**
 * Returns an vee-validate errors ready Object containing the validation errors coming from a status 400 request.
 * @param {Object} apiErrors The errors returned by an api request with 400 status
 * @param {Object} requestBody The request body
 * @returns {Object} An object that can be set as vee-validate errors.
 */
function getValidationErrors(apiErrors, requestBody) {
  const out = {};
  for (const key in requestBody) {
    out[key] = [...(apiErrors[key] ?? [])];
  }

  return out;
}

function successToast(message) {
  Toast.fire({
    icon: 'success',
    title: message ?? "Success!"
  })
}

function dangerToast(message, override) {
  const options = {
    ...{
      title: "Error",
      text: message ?? "Something went wrong!",
      icon: "error",
    },
    ...override,
  };
  Swal.fire(options);
}

// function swal

export { getValidationErrors, dangerToast, successToast };
