<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Legal person") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <input v-model="searchFilter" class="form-control form-control-solid mr-4 min-w-300px" type="text"
          :placeholder="$t('Search')">
        <euro-select v-model="getRole" class="min-w-200px mr-4 mb-0" :multiselect-props="filterRoleMultiselectProps"
          :placeholder="$t('Filter Role')"></euro-select>
        <euro-select v-if="user.is_ap_staff" v-model="getAdministrative" class="min-w-200px mr-4 mb-0"
          :multiselect-props="filterAdministrativeMultiselectProps"></euro-select>
        <router-link :to="{ name: 'create-legal-person' }" class="btn btn-light-primary font-weight-bolder">
          <div class="d-flex">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Home/Building.svg" />
            </span>
            {{ $t("Create") }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="table" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
        :per-page-options="perPageOptions" :filter="searchFilter">

        <template #[`cell.name`]="{ item }">
          <div class="text-capitalize d-flex align-items-center">
            <Avatar :avatar-image="item?.logo" :avatar-text="item?.name?.[0]" />
            <div class="ml-3">
              <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">{{ item?.name }}</div>
              <div class="text-muted font-size-xs">{{ getActiveVat(item.extracompanyvat_set) }}</div>
              <div class="text-muted font-size-xs">{{ getActiveFiscalCode(item.companyfiscalid_set) }}</div>
            </div>
          </div>
        </template>

        <template #[`cell.created_at`]="{ item }">
          <div class="font-weight-normal h-100">{{ formattedDate(item.created_at) }}</div>
        </template>

        <template #[`cell.telephone`]="{ item }">
          <div v-if="item.telcontactscompany_set.length">
            <div class="font-weight-normal">{{ getMainTel(item.telcontactscompany_set) }}</div>
            <div class="text-muted">{{ getMainTelKind(item.telcontactscompany_set) }}</div>
          </div>
        </template>

        <template #[`cell.email`]="{ item }">
          <div v-if="item.emailcompany_set.length">
            <div class="font-weight-normal">{{ getMainEmail(item.emailcompany_set) }}</div>
            <div class="text-muted">{{ getMainEmailKind(item.emailcompany_set) }}</div>
          </div>
        </template>

        <template #[`cell.secondment_participation`]="{ item }">
          <div class="d-flex flex-wrap gap-1">
            <div v-b-tooltip.hover
              :title="item.secondment_client_relations.length > 0 ? $t('Action disabled, used on Assignment') : ''">
              <client-button :active="item.is_client_on_secondments"
                :disabled="item.secondment_client_relations.length > 0"
                @click="setAsClientFunction(item, !item.is_client_on_secondments)"></client-button>
            </div>
            <div v-b-tooltip.hover
              :title="item.secondment_contractor_relations.length > 0 ? $t('Action disabled, used on Assignment') : ''">
              <contractor-button :active="item.is_contractor_on_secondments"
                :disabled="item.secondment_contractor_relations.length > 0"
                @click="setAsContractorFunction(item, !item.is_contractor_on_secondments)"></contractor-button>
            </div>
            <div v-b-tooltip.hover
              :title="item.secondment_partner_relations.length > 0 ? $t('Action disabled, used on Assignment') : ''">
              <supplier-button :active="item.is_partner_on_secondments"
                :disabled="item.secondment_partner_relations.length > 0"
                @click="setAsPartnerFunction(item, !item.is_partner_on_secondments)"></supplier-button>
            </div>
          </div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div v-if="!item.is_administrative_structure" class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover
              :title="isDeleteButtonDisabled(item) ? $t('Delete not allowed, used on Assignment') : $t('Delete')"
              class="btn btn-icon btn-light btn-sm" :class="{ 'btn-hover-primary': !isDeleteButtonDisabled(item) }"
              @click="!isDeleteButtonDisabled(item) ? remove(item) : null">
              <span class="svg-icon svg-icon-md" :class="{ 'svg-icon-primary': !isDeleteButtonDisabled(item) }">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import icons from "@/core/config/icons";
import { dangerToast } from "@/core/helpers";
import { legalPersonSetAsButtonSwal } from "@/core/helpers/swal";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import CompanyService from "@/core/services/company/company.service";
import CompanyEmailService from "@/core/services/company/company-email.service";
import CompanyTelephoneService from "@/core/services/company/company-telephone.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import ClientButton from "@/view/components/buttons/ClientButton.vue";
import ContractorButton from "@/view/components/buttons/ContractorButton.vue";
import SupplierButton from "@/view/components/buttons/SupplierButton.vue";
import Avatar from "@/view/components/avatars/Avatar.vue";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
    ClientButton,
    ContractorButton,
    SupplierButton,
    Avatar,
  },

  data() {
    return {
      icons,
      isLoading: false,
      getAdministrative: "NOT",
      getRole: "All",
      filterAdministrativeSelectOptions: [
        { value: "INCLUDE", text: this.$t("Administrative") },
        { value: "NOT", text: this.$t("Not Administrative") },
        { value: "All", text: this.$t("All") },
      ],
      filterRoleSelectOptions: [
        { value: "client", text: this.$t("Client") },
        { value: "contractor", text: this.$t("Contractor") },
        { value: "supplier", text: this.$t("Supplier") },
        { value: "All", text: this.$t("All") },
      ],
      fields: [
        {
          key: "name",
          label: this.$t("Name"),
          sortable: true,
          class: "legal-person-name",
        },
        { key: "email", label: this.$t("Email"), sortable: false },
        { key: "telephone", label: this.$t("Telephone"), sortable: false },
        {
          key: "secondment_participation",
          label: this.$t("Assignment participation"),
          class: "align-middle",
        },
        { key: "actions", label: this.$t("Actions"), class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapState("user", ["user"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter
      };
    },
    filterRoleMultiselectProps() {
      return {
        options: this.filterRoleSelectOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": true,
      }
    },
    filterAdministrativeMultiselectProps() {
      return {
        options: this.filterAdministrativeSelectOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": false,
      };
    },
    computedWithAdm() {
      switch (this.getAdministrative) {
        case "INCLUDE":
          return true;
        case "NOT":
          return false;
        default:
          return null;
      }
    },
  },

  watch: {
    getAdministrative() {
      this.$refs.table.refresh();
    },
    getRole() {
      this.$refs.table.refresh();
    },
  },

  async mounted() {
    try {
      const [telephoneKinds, emailKinds] = await Promise.all([
        CompanyTelephoneService.getKinds(),
        CompanyEmailService.getKinds(),
      ]);
      this.TELEPHONE_KINDS = telephoneKinds.map((x) => ({ value: x.value, text: x.display_name }));
      this.EMAIL_KINDS = emailKinds.map((x) => ({ value: x.value, text: x.display_name }));
    } catch (err) {
      console.error(err);
      this.$bvToast.toast(this.$t("Failed to initialize. Please try again."), {
        title: this.$t("Error"),
        variant: "danger",
        solid: true,
        noAutoHide: true,
      });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    async setAsClientFunction(company, enable) {
      const res = await legalPersonSetAsButtonSwal(this.$t("Client"), enable);
      if (res.isConfirmed) {
        this.isSettingAsClient = true;
        await CompanyService.update({ secondment_client: enable, name: company.name }, company.id).then(() => {
          company.is_client_on_secondments = enable;
        }).catch(err => {
          this.legalPerson.is_client_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsClient = false;
        });
      }
    },

    async setAsPartnerFunction(company, enable) {
      this.isLoading = true;
      const res = await legalPersonSetAsButtonSwal(this.$t("Supplier"), enable);
      if (res.isConfirmed) {
        this.isSettingAsPartner = true;
        await CompanyService.update({ secondment_partner: enable, name: company.name }, company.id).then(() => {
          company.is_partner_on_secondments = enable;
        }).catch(err => {
          company.is_partner_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsPartner = false;
        });
        this.isLoading = false;
      }
    },

    async setAsContractorFunction(company, enable) {
      const res = await legalPersonSetAsButtonSwal(this.$t("Contractor"), enable);
      if (res.isConfirmed) {
        this.isSettingAsContractor = true;
        await CompanyService.update({ secondment_contractor: enable, name: company.name }, company.id).then(() => {
          company.is_contractor_on_secondments = enable;
        }).catch(err => {
          console.log(err);
          company.is_contractor_on_secondments = !enable;
          dangerToast(err?.response?.data?.error);
        }).finally(() => {
          this.isSettingAsContractor = false;
        });
      }
    },

    itemProvider(ctx, callback) {
      const { currentPage, perPage, filter, sortBy, sortDesc } = ctx;
      const kind = 0;
      const role = this.getRole === "All" ? null : this.getRole;
      CompanyService.getAll(perPage, currentPage, sortBy, filter, sortDesc, null, this.computedWithAdm, kind,
        'id,name,logo,extracompanyvat_set,companyfiscalid_set,telcontactscompany_set,emailcompany_set,is_client_on_secondments,is_contractor_on_secondments,is_partner_on_secondments,is_administrative_structure,secondment_client_relations,secondment_contractor_relations,secondment_partner_relations', null, role)
        .then((res) => {
          this.total = res.count;
          callback(res.results);
        })
        .catch(() => callback([]));

      return null;
    },

    edit(item) {
      this.$router.push({
        name: "detail-legal-person",
        params: { companyID: item.id },
      });
    },

    remove(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete")} ${item.name}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            await CompanyService.delete(item.id);
            Swal.fire(this.$t("Success"), this.$t("Legal Person deleted successfully"), "success");
            this.$refs.table.refresh();
          } catch (err) {
            dangerToast(err?.response?.data?.error);
          }
        }
      });
    },

    formattedDate(timestamp) {
      const [date] = new Date(timestamp).toISOString().split("T");
      return date;
    },

    extendedCountryName(iso2) {
      const x = this.countries?.find((el) => el.iso == iso2);
      if (!x) return iso2;
      return x.name;
    },

    getActiveFiscalCode(fiscalCodes) {
      if (!fiscalCodes.length) {
        return "";
      }

      const code = fiscalCodes.find((el) => el.is_active);
      if (!code) {
        return "";
      }

      return this.$t("Fiscal Code") + ': ' + code.fiscalid;
    },

    getActiveVat(vats) {
      if (!vats.length) {
        return "";
      }

      const vat = vats.find((el) => el.is_active);
      if (!vat) {
        return "";
      }

      return this.$t("Vat") + ': ' + vat.vat;
    },

    getMainTel(telephones) {
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return tel?.number || notMain?.number || "";
    },

    getMainTelKind(telephones) {
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return (
        this.TELEPHONE_KINDS.find((el) => el.value == tel?.kind)?.text ||
        this.TELEPHONE_KINDS.find((el) => el.value == notMain?.kind)?.text ||
        ""
      );
    },

    getMainEmail(emails) {
      if (!emails.length) {
        return "";
      }
      const email = emails[0];
      if (!email) {
        return "";
      }
      return email.email;
    },

    getMainEmailKind(emails) {
      if (!emails.length) {
        return "";
      }
      const email = emails.find((el) => el.main);
      if (!email) {
        return "";
      }
      const emailKind = this.EMAIL_KINDS.find((el) => el.value == email.kind);
      if (!emailKind) {
        return "";
      }
      return emailKind.text;
    },

    getMainAddress(addresses) {
      if (!addresses.length) {
        return "";
      }

      if (addresses.length === 1) {
        return addresses[0].address.formatted;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }

      return address.address.formatted;
    },

    getMainAddressDescription(addresses) {
      if (!addresses.length) {
        return "";
      }
      if (addresses.length === 1) {
        return addresses[0].description;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }
      return address.description;
    },
    isDeleteButtonDisabled(item) {
      if (item.secondment_client_relations.length > 0) {
        return true;
      }
      if (item.secondment_contractor_relations.length > 0) {
        return true;
      }
      if (item.secondment_partner_relations.length > 0) {
        return true;
      }
      return this.user.selected_company == item.id
    }
  },
};
</script>

<style scoped>
.company-name {
  font-size: 0.8rem;
}

::v-deep .legal-person-name {
  min-width: 10ch;
}

::v-deep .address-cell div {
  white-space: break-spaces;
  max-width: 25ch;
}

::v-deep .address-cell .address {
  text-overflow: ellipsis;
}

.gap-1 {
  gap: 1rem;
}

.text-capitalize {
  text-transform: capitalize;
}
</style>
