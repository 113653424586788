var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "d-inline-block",
    attrs: {
      "show": _vm.$attrs.loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-variant": "primary"
    }
  }, [_c('button', _vm._g(_vm._b({
    staticClass: "btn font-weight-bolder btn-xs",
    class: {
      'btn-light-primary': !_vm.active,
      'btn-light-success': _vm.active
    },
    attrs: {
      "disabled": _vm.disabled
    }
  }, 'button', Object.assign({}, _vm.$attrs), false), _vm.$listeners), [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }